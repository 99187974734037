/* bg */
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: linear-gradient(
    135deg,
    #FFE5E5,
    #FFD1DC,
    #FFE5E5
  );
  background-attachment: fixed;
}

/* navbar */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  background: linear-gradient(
    to bottom,
    #FFD1DC,
    #FFE5E5
  );
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 1000;
  padding: 20px 0;
  margin-bottom: 100px;
}

.nav-content {
  max-width: 1400px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  white-space: nowrap;
}

/* section */
.section {
  min-height: auto;
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  background: transparent;
}

.home-section {
  min-height: 60vh;
  padding-top: 100px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.about-section,
.tokenomics-section,
.roadmap-section {
  background-color: transparent;
}



.nav-menu {
  display: flex;
  align-items: center;
  gap: 45px;
  white-space: nowrap;
}

.nav-logo {
  height: 100px;
  width: auto;
}

.nav-brand {
  font-size: 28px;
  font-weight: bold;
  color: #FF6B88;
  margin-right: 30px;
}

.navbar button {
  background: none;
  border: none;
  padding: 15px 30px;
  font-size: 24px;
  color: #FF6B88;
  cursor: pointer;
  transition: color 0.3s;
  font-weight: 600;
}

.navbar .buy-button {
  background-color: #4CAF50;
  color: white;
  border-radius: 30px;
  padding: 18px 35px;
  font-size: 24px;
  font-weight: bold;
  margin-left: 20px;
  display: none;
}

.navbar button:hover {
  color: #FF4D6D;
}


.section h1 {
  font-size: 48px;
  margin-bottom: 40px;
  color: #333;
}

.section h2 {
  font-size: 42px;
  margin-bottom: 30px;
  color: #333;
}

.section p {
  font-size: 24px;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}

.balance-box {
  flex: 1;
  padding: 40px 60px; 
  border: 2px solid #FF6B88;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.35);
  backdrop-filter: blur(10px);
  min-width: 450px;
  max-width: 800px;
  box-shadow: 0 4px 15px rgba(255, 107, 136, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.balance-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(255, 107, 136, 0.3);
}

.balance-box h3 {
  font-size: 34px;
  color: #FF4D6D;
  margin: 0 0 35px 0;
  text-align: center;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.8);
}

.balance-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.gauge-container {
  width: 100%;
  height: 55px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 27.5px;
  overflow: hidden;
  position: relative;
  border: 1px solid rgba(255, 107, 136, 0.3);
}

.gauge-fill {
  height: 100%;
  background: linear-gradient(to right, #FF4D6D, #FF8FA3);
  border-radius: 27.5px;
  transition: width 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 0 10px rgba(255, 77, 109, 0.3);
}

@media (max-width: 768px) {
  .balance-content {
    gap: 15px;
    padding: 10px;
  }

  .gauge-container {
    height: 35px;
  }

  .balance-text {
    font-size: 16px;
  }

  .presale-content {
    width: 100%;
    padding: 10px;
  }
}

.gauge-text {
  position: absolute; 
  width: 100%; 
  text-align: center; 
  left: 0; 
  color: black;
  font-weight: bold;
  font-size: 26px; 
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  letter-spacing: 0.5px;
  z-index: 1; 
}

.balance-text {
  margin: 20px 0 0 0;
  font-size: 26px;
  color: #333;
  text-align: center;
  font-weight: 500;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.8);
}


.balance-box p {
  text-align: center;
  color: #FF4D6D;
  font-size: 22px;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .nav-menu {
    gap: 10px; 
  }
  .nav-menu button {
    display: none;  
  }
  
  .nav-logo {
    height: 80px;  
    margin-right: 8px; 
  }
 
  .nav-brand {
    font-size: 24px;  
    margin-right: 0; 
  }
 }


@media (max-width: 1800px) {
  .balance-container {
    width: 95%;
  }
  
  .balance-box {
    min-width: 400px;
    padding: 35px 50px;
  }
}

@media (max-width: 1400px) {
  .balance-box {
    min-width: 350px;
    padding: 30px 40px;
  }
  
  .gauge-text {
    font-size: 24px;
  }
  
  .balance-text {
    font-size: 24px;
  }
}

@media (max-width: 1200px) {
  .balance-box {
    min-width: 300px;
    padding: 25px 30px;
  }
}

@media (max-width: 900px) {
  .balance-container {
    flex-direction: column;
    align-items: center;
    gap: 40px;
    top: 0;
  }

  .balance-box {
    width: 90%;
    max-width: none;
    min-width: auto;
  }

  .welcome-image {
    max-width: 100%; 
    height: auto; 
    margin-bottom: 1rem;
  }

  .balance-text {
    color: #ff69b4;
    font-size: 16px;
    margin-top: 10px;
  }

  .notice-box {
    padding: 20px 40px;
    margin-top: 20px;
  }
  
  .notice-box h4 {
    font-size: 24px;
    color: #FF4D6D;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .notice-box ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .notice-box li {
    color: #666;
    font-size: 18px;
    margin-bottom: 12px;
    padding-left: 20px;
    position: relative;
    line-height: 1.5;
  }
  
  .notice-box li:before {
    content: "•";
    color: #FF4D6D;
    position: absolute;
    left: 0;
    font-size: 18px;
  }
  
}


.footer {
  width: 100%;
  background: linear-gradient(
    to bottom,
    #FFE5E5,
    #FFD1DC
  );
  padding: 40px 0 20px;
  margin-top: 60px;
  border-top: 1px solid rgba(255, 107, 136, 0.2);
}

.footer-content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-top {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  gap: 50px; 
}

.social-links {
  display: flex;
  gap: 50px; 
  align-items: center;
}


.footer-logo {
  margin-left: 50px;
}

.social-icon {
  width: 100px;
  height: 100px;
  transition: transform 0.3s ease;
  object-fit: contain;
}

.footer-logo-img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.footer-bottom {
  border-top: 1px solid rgba(255, 107, 136, 0.2);
  padding-top: 20px;
}

.risk-warning {
  color: #666;
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}


@media (max-width: 768px) {
  .footer {
    padding: 30px 0 15px;
  }

  .footer-top {
    flex-direction: column;
    gap: 20px;
  }

  .social-links {
    justify-content: center;
    gap: 30px; 
  }

  .social-icon,
  .footer-logo-img {
    width: 80px; 
    height: 80px;
  }

  .footer-logo {
    order: -1;
  }

  .risk-warning {
    font-size: 12px;
    padding: 0 20px;
  }

  
}


.section-divider {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  overflow: hidden; 
}


.crayon-line {
  width: 95%; 
  height: 12px;
  background: linear-gradient(
    90deg,
    transparent 0%,
    #FFB6C1 15%, 
    #FFB6C1 85%, 
    transparent 100%
  );
  opacity: 0.7;
  position: relative;
  border-radius: 6px;
  filter: blur(3px);
}

.crayon-line::before {
  content: '';
  position: absolute;
  top: -2px;
  left: 5%; 
  right: 5%; 
  height: 100%;
  background: linear-gradient(
    90deg,
    #FFC0CB 5%,
    #FFB6C1 30%,
    #FF69B4 70%,
    #FFC0CB 95%
  );
  opacity: 0.4;
  border-radius: 6px;
  transform: rotate(-0.5deg);
}

.crayon-line::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 2%; 
  right: 2%; 
  height: 100%;
  background: linear-gradient(
    90deg,
    #FFB6C1 10%,
    #FFC0CB 50%,
    #FFB6C1 90%
  );
  opacity: 0.3;
  border-radius: 6px;
  transform: rotate(0.3deg);
}

@media (max-width: 768px) {
  .section {
    padding: 50px 0px;
  }
  
  .crayon-line {
    width: 90%;
    height: 10px;
  }
  
  .section-divider {
    height: 40px;
    padding: 15px 0;
  }
}

.roadmap-container,
.about-container,
.tokenomics-container,
.presale-container {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 0 20px;
}


@media (max-width: 768px) {
  .roadmap-container,
  .about-container,
  .tokenomics-container,
  .presale-container {
    padding: 0 10px; 
    max-width: 97%; 
  }
}

.balance-box {
  width: 100%; 
  max-width: 100%; 
  margin: 0;
  padding: 35px;
  border: 2px solid #FF6B88;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.35);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 15px rgba(255, 107, 136, 0.2);
}


.presale-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.presale-content h3 {
  color: #FF6B88;
  font-size: 32px;
  margin: 0;
  text-align: center;
  text-shadow: 1px 1px 2px rgba(255, 107, 136, 0.2);
}

.presale-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}

.start-date {
  text-align: center;
  padding: 15px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 12px;
  border: 1px solid rgba(255, 107, 136, 0.2);
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
}

.countdown-container {
  text-align: center;
}

.countdown-grid {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 15px;
}

.time-block {
  min-width: 80px;
  padding: 15px 10px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 12px;
  border: 1px solid rgba(255, 107, 136, 0.2);
}

.time-value {
  font-size: 32px;
  font-weight: bold;
  color: #FF6B88;
  display: block;
}

.time-label {
  font-size: 14px;
  color: #666;
  display: block;
  margin-top: 5px;
  text-transform: uppercase;
}

.info-label {
  font-size: 18px;
  color: #666;
  margin-bottom: 8px;
}

.date-value {
  font-size: 24px;
  color: #FF6B88;
  font-weight: 600;
}



@media (max-width: 768px) {
  .balance-box {
    padding: 20px;
    width: 100%;
    max-width: 100%;
  }

  .time-block {
    min-width: 60px;
    padding: 10px 5px;
  }

  .time-value {
    font-size: 24px;
  }

  .time-label {
    font-size: 12px;
  }

  .presale-content h3 {
    font-size: 28px;
  }

  .date-value {
    font-size: 20px;
  }
}

.wallet-info {
  display: flex;
  flex-direction: column;
  align-items: center;  /* Center align content */
  width: 100%;
  gap: 8px;
 }

 .wallet-address-container {
  display: flex;
  align-items: center;
  gap: 8px;
 }
 
 .wallet-address {
  display: inline-block;
  padding: 2px 3px;
  font-size: 22px;
  background-color: rgba(255, 255, 255, 0.4);  /* White background only for address */
  border-radius: 8px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
 }

 .button-container {
  display: flex;
  gap: 12px;
 }

 .wallet-address-container {
  display: inline-block;  /* Container fits the address */
  max-width: 100%;       /* Don't overflow parent */
 }


.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  padding: 6px;
  border: none;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 8px;
  cursor: pointer;
  color: #666;
  transition: all 0.2s ease;
  flex-shrink: 0; 
}

.icon-button svg {
  width: 22px;
  height: 22px;
}

@media (max-width: 1024px) {
  .wallet-address {
    font-size: 18px;
    padding: 3px 4px;
  }
}



@media (max-width: 768px) {
  .wallet-info {
    flex-direction: column;  /* Change to vertical layout */
    align-items: center; /* Align items to the start */
    gap: 4px; 
  }
  
  .wallet-address {
    font-size: 14px;
    padding: 2px 2px;
  }
  
  .icon-button {
    width: 24px;
    height: 24px;
  }
  
  .icon-button svg {
    width: 16px;
    height: 16px;
  }
}


/* Small mobile screens */
@media (max-width: 480px) {
  .wallet-info {
    flex-direction: column;  /* Change to vertical layout */
    align-items: center; /* Align items to the start */
    gap: 8px;
  }
 
  .wallet-address {
    font-size: 11px;
    padding: 6px 10px;
  }

  .wallet-address-container {
    flex-direction: column;  /* Stack vertically on mobile */
    width: 100%;
    align-items: center;
  }
  
  /* Button container for horizontal alignment */
  .button-container {
    margin-top: 8px;
    justify-content: center;
  }
  
  .icon-button {
    width: 22px;
    height: 22px;
  }
  
  .icon-button svg {
    width: 14px;
    height: 14px;
  }
 }


.tokenomics-item {
  background: rgba(255, 255, 255, 0.35);
  backdrop-filter: blur(10px);
  border: 2px solid #FF6B88;
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 30px;
}

.tokenomics-item h3 {
  color: #FF4D6D;
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
}

.token-supply {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.supply-number {
  font-size: 52px;
  font-weight: 800; 
  color: #FF4D6D; 
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 1px;
  padding: 10px 0;
  text-shadow: 
    2px 2px 0 rgba(255, 255, 255, 0.9),
    -2px -2px 0 rgba(255, 255, 255, 0.9); 
  transition: transform 0.3s ease;
}

.supply-number:hover {
  transform: scale(1.02);
}

.supply-note {
  color: #444; 
  font-size: 20px;
  font-style: italic;
  font-weight: 500;
  margin-top: 10px;
}


@media (max-width: 768px) {
  .supply-number {
    font-size: 36px;
    letter-spacing: 0.5px;
  }
  
  .supply-note {
    font-size: 16px;
  }
}

.distribution-info {
  text-align: center;
  color: #333;
}

.presale-info {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 500;
}

.funds-info {
  font-size: 20px;
  line-height: 1.6;
}

.funds-info ul {
  list-style: none;
  padding: 0;
  margin: 15px 0;
}

.funds-info li {
  font-size: 18px;
  margin: 10px 0;
  color: #555;
}

@media (max-width: 768px) {
  .tokenomics-item {
    padding: 20px;
  }

  .supply-number {
    font-size: 28px;
  }

  .supply-note {
    font-size: 16px;
  }

  .presale-info {
    font-size: 20px;
  }

  .funds-info {
    font-size: 18px;
  }

  .funds-info li {
    font-size: 16px;
  }
}

.about-content {
  text-align: center;
  padding: 30px;
  background: rgba(255, 255, 255, 0.35);
  backdrop-filter: blur(10px);
  border: 2px solid #FF6B88;
  border-radius: 15px;
}

.about-flex {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 25px;
}

.about-text-container {
  flex: 1;
  text-align: left; 
}

.about-logo {
  width: 200px; 
  flex-shrink: 0; 
}

.about-logo img {
  width: 100%;
  height: auto;
  object-fit: contain;
}


.about-text {
  font-size: 22px;
  line-height: 1.6;
  margin-bottom: 25px;
  color: #444;
}

.about-text:last-child {
  margin-bottom: 0;
}

.about-text.highlight {
  color: #FF4D6D;
  font-size: 24px;
  font-weight: 500;
}

/* 모바일 반응형 */
@media (max-width: 768px) {
  .about-flex {
    flex-direction: column;
    gap: 20px;
  }

  .about-text-container {
    text-align: center; 
  }

  .about-logo {
    width: 150px; 
  }

  .about-text {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .about-text.highlight {
    font-size: 20px;
  }
}

.hodl-message {
  position: relative;
  text-align: center;
  padding: 40px;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  border: 3px solid #FF6B88;
  border-radius: 20px;
  animation: float 3s ease-in-out infinite;
}

.hodl-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.hodl-text {
  font-size: 48px;
  font-weight: bold;
  color: #FF4D6D;
  text-shadow: 
    2px 2px 0 #fff,
    -2px -2px 0 #fff,
    2px -2px 0 #fff,
    -2px 2px 0 #fff;
  animation: pulse 2s ease-in-out infinite;
}

.hodl-and {
  font-size: 36px;
  color: #FF8FA3;
  font-weight: bold;
}

.sparkles {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.sparkles::before,
.sparkles::after {
  content: '✨';
  position: absolute;
  font-size: 24px;
  animation: sparkle 1.5s ease-in-out infinite;
}

.sparkles::before {
  top: 20%;
  left: 15%;
}

.sparkles::after {
  bottom: 20%;
  right: 15%;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

@keyframes sparkle {
  0%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.8);
  }
}


.hodl-message:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(255, 107, 136, 0.2);
  transition: all 0.3s ease;
}

.hodl-text:hover {
  animation: shake 0.5s ease-in-out;
}

@keyframes shake {
  0%, 100% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  75% { transform: rotate(-5deg); }
}


@media (max-width: 768px) {
  .hodl-text {
    font-size: 36px;
  }
  
  .hodl-and {
    font-size: 28px;
  }
  
  .hodl-message {
    padding: 30px;
  }
  
  .hodl-text-wrapper {
    gap: 15px;
  }
}

.faq-section {
  background: transparent;
}

.faq-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.faq-item {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.faq-question {
  width: 100%;
  padding: 20px;
  background: none;
  border: none;
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  color: #333;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
}

.faq-question:hover {
  background: rgba(255, 255, 255, 0.2);
}

.faq-icon {
  font-size: 24px;
  font-weight: 300;
  color: #666;
  transition: transform 0.3s ease;
}

.faq-item.open .faq-icon {
  transform: rotate(180deg);
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  padding: 0 20px;
  background: rgba(255, 255, 255, 0.1);
}

.faq-item.open .faq-answer {
  max-height: 500px;
  padding: 20px;
}

.faq-answer p {
  margin: 0;
  color: #444;
  font-size: 16px;
  line-height: 1.6;
  white-space: pre-wrap;
}

@media (max-width: 768px) {
  .faq-container {
    padding: 0 20px;
  }
  
  .faq-question {
    font-size: 16px;
    padding: 16px;
  }
  
  .faq-answer p {
    font-size: 14px;
  }
}